

#receivePromotions .modal-wrapper{
	padding:10px!important;
}

#receivePromotions #promo-header {
    background-image: url('images/receive_promo.jpg');
    margin: 0 0 10px 0;
    min-height: 220px;
    display: list-item;
    background-position: bottom center;
    background-size: contain;
    background-color: #a089ce;
    background-repeat: no-repeat;
}
#receivePromotions .sec-message {
	font-weight: 600!important;
}

#receivePromotions .radio {
	margin: 15px;
}

#receivePromotions .radio label{
	display:block;
    width: 150px;
    margin: 0 auto 5px auto;
}

#receivePromotions #promo-header img {
	max-width: 100%;
	margin: 15px auto;
	display: block;
    max-height: 60px;
}

@media(max-width:789px){ 
	#receivePromotions #promo-header img {
		background-size: 150%!important;
		max-width: 205px!important;
	}

	#receivePromotions #promo-header {
	    min-height: 200px;
	    background-size: cover;
	    background-position: bottom;
	}
}

.form-register {
    margin-bottom: 0;
    padding: 0;
}

.form-register .checkbox label{
    margin: 0px;
    padding: 0px;
}

.form-register .checkbox span:not(.cr){
	display: inline;
    width: calc(100% - 27px);
    font-size: 1.3rem;
}

.form-register .cr-icon {
	color:#ea732d!important;
}

.form-register a {
    font-weight: 800;
    text-decoration: underline;
    font-size: inherit;
    color: unset;
}