.body-wrapper {
	margin-top: 100vh;
}
/* ***** Start Style on Navigation Menu ***** */
div.navigation {
	position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 5;
    height: 130px;
	margin: 0 auto;
	padding: 3rem;
}
div.navigation  #bs-navbar {
	height: 100% !important;
}

#register, #login {
	background-repeat: no-repeat;
	background-position: calc(100% - 15px) center;
	background-size:20px;
	text-transform: uppercase;
}

#register{}
#login{}

#log-in-and-register {
		display: flex;
}
.login-register-top{
	align-items: flex-end;
	flex-direction: column;
	position: fixed;
	right: 4.5rem;
	top: 2rem;
}
.login-register-scroll {
	flex-flow: wrap-reverse;
	float: right;
}
#text-login, #text-register,#text-menu {
	position: absolute;
	margin: 0;
	right: 6rem;
	margin-top: 1rem;
	width: 10rem;
	text-transform: uppercase;
	visibility: hidden;
	opacity: 0;
	/* transition effect */
	transition: opacity .2s, visibility .2s;
}
#text-menu{
	position: absolute;
	padding-left: 0;
	left: 20px;
	margin-left: 5.5rem;
	margin-top: 1.5rem;
}
#effect-icon-login,#effect-icon-register{
	margin-left: 20px;
}
#effect-icon-login img {
	margin-bottom: 1rem;
}
#effect-icon-menu:hover #text-menu,
#effect-icon-login:hover #text-login,
#effect-icon-register:hover #text-register {
	visibility: visible;
	opacity: 1;
}
#effect-icon-logout{
	color: #000000;
}

#effect-icon-logout:hover #text-menu{
	visibility: visible;
	opacity: 1;
}
#menu-modal{
	padding: 0;
}
#menu-modal.modal.left.fade .modal-dialog{
	left: -348px;
	-webkit-transition: opacity 0.5s linear, left 0.5s ease-out;
	-moz-transition: opacity 0.5s linear, left 0.5s ease-out;
	-o-transition: opacity 0.5s linear, left 0.5s ease-out;
	transition: opacity 0.5s linear, left 0.5s ease-out;
}
#menu-modal.modal.left.fade.in .modal-dialog{
	left: 0;
}
#menu-modal.modal.left .modal-content{
		height: 100%;
		overflow-y: auto;
}
#menu-modal .menu-modal-left  button.close{
	background-image: url(../images/icons/CruzIcono.jpg)!important;
	color:white!important;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}
#menu-modal.modal.left .modal-content{
	overflow-y: auto;
}
#menu-modal .modal-dialog{
	position: relative;
	background: WHITE;
	height:100VH;
	margin: 0;
	padding: 5rem 3rem;
	width: 27%;
	left: 0;
	-webkit-transform: translate3d(0%, 0, 0);
	-ms-transform: translate3d(0%, 0, 0);
	-o-transform: translate3d(0%, 0, 0);
	transform: translate3d(0%, 0, 0);
}
.menu-modal-data ul li {
	margin-top: 3em ;
}
.menu-modal-data ul li a {
	font-weight: 100;
	color: rgba(100, 98, 99, 0.8);
	text-decoration: none!important;
	font-size: 16px;
	letter-spacing: 4px;
}
.menu-modal-data ul li hr {
	position: relative;
	border-top: 4px solid rgb(98,197,202) !important;
	width: 10rem;
	margin: 0;
	padding: 0;
}
/* ***** End Style on Navigation Menu ***** */

.details-box h3 {
	padding-left: 0!important;
	padding-right: 0!important;
	padding-top: 0!important;
	font-family: inherit!important;
	color: inherit!important;
	font-size:24px!important;
}

.do-invite {
	line-height: 2!important;
	cursor: pointer!important;
}


#bg-loading{
	background-color: #000;
	height: 100%;
	left: 0;
	opacity: .8;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9998;
	display:none;
}

#loading{
	position:fixed;
	top: calc(50% - 140px);
	width: 100%;
	max-width: 562px;
	max-height: 270px;
	height: 100%;
	background-color: #f6f6f6 !important;
	text-align: center;
	padding: 0;
	z-index: 9999;
	display: none;
}

#loading .brand-logo,
#loading .load{
	display:block;
	text-align: center;
	clear:both;
}

#loading .brand-logo{
	padding-bottom: 20px;
}

#loading .brand-logo img {
	border:3px solid black;
	width: 50px;
}


#loading .load{
	color: #5b5b5b;
	text-transform: uppercase;
	font-size: 18px;
	border-top: 1px solid #6f7c85;
	border-bottom: 1px solid #6f7c85;
	padding: 0px;
}

#loading .load img{
	height: 100%;
	width: 100%;
}
@font-face {
	font-family: Poppins-Bold;
	src: url(../fonts/Poppins-Bold.ttf);
}

@font-face {
	font-family: Poppins-Light;
	src: url(../fonts/Poppins-Light.ttf);
}

@font-face {
	font-family: Poppins-Medium;
	src: url(../fonts/Poppins-Medium.ttf);
}

@font-face {
	font-family: Poppins-Regular;
	src: url(../fonts/Poppins-Regular.ttf);
}

@font-face {
	font-family: Poppins-SemiBold;
	src: url(../fonts/Poppins-SemiBold.ttf);
}

body.full header{
	display: none;
}

header.top{
	position:fixed;
	background-color:#111111;
	z-index: 115;
}
header, footer {
	display:block;
}
* {
	font-family: 'Poppins-Regular', sans-serif;
	font-size: 16px;
	line-height: 1.6;

}

#background-beach,
#background-profile{
	position: fixed;
	background-position: center center;
	background-size: cover;
	display:block;
	color:white;
	font-size:30px!important;
	padding: 0;
	top: 0;
	height: 100vh;
	width: 100%;
	z-index: -1;
}

#myCarousel .carousel-inner  img {
	height: 100vh;
    object-fit: cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

}
#myCarousel .carousel-indicators .downmouse{
	margin: 16em auto;
}
#background-profile {
	background-image: url('images/beach-rocks.jpg');
}
#account-info,
#profile-option{
	margin-bottom: -26rem;
	height: 100vh;
	background: transparent;
}
a:hover,
a:link,
a:visited
a:active {
	text-decoration: none;
	cursor:pointer;
}
body {
	color: #6a6a6a;
}
p{
	font-size: 14px;
	line-height: 1.8;
}
header {
	background-color: transparent;
	color: #fff;
	padding: 2rem;
	position: fixed;
	display: block;
	width: 100%;
	top: 0;
	z-index: 1;
}
header a.main-logo{
	position: absolute;
	top: 28px;
	left: 0;
	right: 0;
}
header a.main-logo span {
	background-color: white;
	border: 4px solid black;
	padding: 20px;
	display:inline-block;
}
header a.main-logo img {
	max-width:90px;
}
header a {
	color: #ffffff;
}
header a:hover {
	color: #5b5b5b;
}
#beach-background {
	background-image: url('images/beach-relax-background.jpg');
	display: block;
	height: 560px;
	background-size: cover;
	background-position: center;
}
.description h2, .description {
	color: white;
}
.description h2 {
	padding:0;
	font-size: 80px;
	margin:10px;
}

.description {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30%;
	margin: auto;
	font-size: 30px;
    text-align: center;
}

.description hr {
	width: 300px;
	margin-bottom: 20px;
	margin-top: 20px ;
}
.carousel-indicators {
	position: absolute;
	top: 4.5em;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    margin: auto;
    font-size: 30px;
    text-align: center;
}
.carousel-indicators li{
	width: 14px;
    height: 14px;
}
.carousel-indicators li.active{
	width: 15px;
    height: 15px;
}
.title hr {
	background-color: rgb(98,197,202);
	padding-top: 1px;
	border-top: 4px solid rgb(98,197,202) !important;
	width: 200px
}
.title h2 {
	color: #333333;
}
hr {
	margin-top: 0;
	box-shadow: none;
	border-top: 4px solid white !important;
}

.white-belt {
	position: relative;
	font-size: 18px;
	background: white;
	padding:  0;
	margin: 0 ;
	text-align: center;
	height: auto;
	padding-top: 2rem;
}
.white-belt h2{
	font-size: 36px;
    margin: 1%;
    letter-spacing: 10px;
    font-weight: 900;
}
.hori{
	background-color: white!important;
	margin: 0.5em auto;
	padding-top: 0px!important;

}
#companies {
	position: relative;
	height: 100vh;
	/*margin-top: 10em;*/
}
.options a{
	color: #5b5b5b;
	text-transform: uppercase;
	font-weight: 600;
	font-family: 'Poppins-Regular', sans-serif;
}

h3 {
	/*font-family: 'Poppins-Light', sans-serif;*/
	padding: 15px 10px;
	font-size:20px;
	color: #1b1b1b;
}

.program-prices h2{
	font-size: 22px;
}
h2 {
	font-family: 'Poppins-Regular', sans-serif;
	font-weight: 600;
	font-size:44px;
	color: #5b5b5b;
	margin-bottom: 50px;
	text-transform: uppercase;
}
hr {
	width: 50px;
	background-color:#DADADA;
	padding-top: 1px;
	margin-bottom: 60px;
	box-shadow: none;
}
.program-options {
	padding-top:85px;
	padding-bottom:80px;
}
.program-options h2{
	font-size: 28px;
	margin-bottom: 0px;
	font-family: 'Poppins-Regular', sans-serif;
}
.program > div {
	padding-left:0;
	padding-right:0;
}
.program .title {
	color: black;
	font-size: 20px;
	font-family: 'Poppins-Light', sans-serif;;
}
.program .price {
	color: black;
	font-size: 22px;
}
footer{
	position: relative;
	background-color:#111111;
	color: #cfcfcf;
	padding: 25px;
	font-family: 'Poppins-Light', sans-serif;
	height: 25rem;

}
footer .container,footer {
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
}

footer a,
footer a:hover,
footer a:visited,
footer a:link,
footer a:active {
	color:inherit;
}

footer a:hover{
	text-decoration: underline;
}

footer ul{
	list-style: none;
}

footer ul li {
	display: inline-block;
	margin-left: 2rem;
}

footer ul li:before {
	content: '\00B7\00a0';
}

footer div.left {
	text-align: left;
}

footer div.right {
	text-align: right;
}

.bordered {
	background-color: #f6f6f6;
	padding: 5rem 8rem;
	margin-top:5px;
}

#register-modal .modal-dialog,
#message .modal-dialog{
	width: 750px;
}
#register-modal .title{
	background-image: url('../images/icons/register.png');
	background-repeat: no-repeat;
	background-size: 22px;
	background-position: calc(77%) calc(20%);
}

#login-modal .title{
	background-image: url('../images/icons/SignIn.png');
	background-repeat: no-repeat;
	background-size: 22px;
	background-position: calc(81%) calc(55%);
}

*[data-role="submit"],
input[type="submit"],
button.btn-payment,
.btn-cancel,
.btn-continue{
	color: white;
	background-color: #000000!important;
	border-radius: 2px;
	padding: 10px;
	margin: 2rem 0;
	letter-spacing: 3px;
	cursor:pointer;
	border:none;
	width:100%;
	text-transform: uppercase;
}
.btn-contact-send {
	border: 3px solid #ffffff;
	color: rgb(98,197,202);
	font-size: larger;
	text-transform: uppercase;
	background-color: transparent !important;
	font-weight: bold;
}
.btn-cancel {
	background-color: #bfbfbf!important;
}

.btn-continue,
.btn-continue:hover{
	background-color: #5b5b5b!important;
	color: white!important;
	display: block;
	text-transform: unset!important;
	font-size: inherit!important;
}

button.btn-payment {
	width: calc(100% - 20px);
}
*[data-role="change"]:hover,
*[data-role="change"]:link,
*[data-role="change"]:active,
*[data-role="change"]:visited{
	text-transform: uppercase;
	color:#5b5b5b;
	font-size:14px;
	text-decoration: underline!important;
}
a.do-fb:hover,
a.do-fb:link,
a.do-fb:active,
a.do-fb:visited {
	color: white;
	padding: 10px 40px 10px 10px;
	text-transform: none;
	background-color: #3b5998;
	width: 100%;
	display: block;
	font-size: 18px;
	border-radius: 2px;
	background-repeat: no-repeat;
	background-image: url(images/facebook.png);
	background-position: 95% center;
}

.modal-body {
	color: white;
}

.form-control {
	border-radius: 0;
	text-align: center;
	color: #5b5b5b;
	font-size: 16px;
}
#contact-us {
	background-image: url('https://repository.mundoinspira.com/gdsvacations/BackImage_ContactForm.jpg');
	height: inherit;
	background-position: center center;
	background-size: cover;
	display: block;
	color: white;
	font-size: 30px!important;
	padding: 10rem 20rem;
	height: 100%;
	max-height: 100%;
}
#contact-us.white-belt h2,#contact-us.white-belt h3 {
	color: white;
	font-weight: bold;
}

#contact-us hr.vertical {
	height: -webkit-fill-available;
	display: block;
	padding: 100% 0;
	vertical-align: middle;
	margin: 0;
	width: 1px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 50%;
	max-height: 210px;
}
#contact-us h3 {
	font-size: 24px;
	margin-bottom: 0;
	color: #5b5b5b;
	/*font-family: 'Poppins-SemiBold',sans-serif;*/
	padding: 30px 0 20px 0;
}
#contact-us p {
	font-size: medium;
}
#contact-us .contact-numbers {
	font-weight: 600;
}

#contact-us div.name {
	margin-top: 80px;
}

#contact-us span{
	font-size: 22px;
	font-family: "Poppins-Light";
}

#contact-us .form-control {
	text-align: left;
}
#contact-us .contact-membership,
#contact-us .contact-reservation{
	position: relative;
  margin: 0 auto;
  border-bottom: 4px solid #62c5ca;
  width: 40%;
}
/**** "floating-Lable style start" ****/
 .floating-label,.floating-label {
	position: relative;
	margin-bottom: 5rem;
}
.floating-input, .floating-input {
  font-size:14px;
  padding:4px 4px;
  display:block;
  width:100%;
  height:30px;
  background-color: transparent !important;
  border:none;
	border-bottom:1px solid #ffffff ;
	text-align: center;
}
.login-register .floating-input,
.login-register .floating-input  {
	border-bottom: 1px solid #cccccc !important;
	text-align: center;
	color: #000000 !important;
}
.login-register .floating-input:focus  {
	outline:none;
	border-bottom:2px solid #cccccc !important;
}
.login-register .floating-label label {
	color: #737373 !important;
}
.login-register .floating-input:focus ~ label, .login-register .floating-input:not(:placeholder-shown) ~ label {
  color:#000000 !important;
}
 .floating-input:focus  {
     outline:none;
     border-bottom:2px solid rgb(255, 255, 255);
}

 .floating-label label {
  color:rgb(255, 255, 255) ;
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:0;
	top:0;
	text-transform: uppercase;
	text-align: center;
  transition:0.2s ease all;
  -moz-transition:0.2s ease all;
	-webkit-transition:0.2s ease all;
	width: 100%;
}
 .floating-input:focus ~ label, .floating-input:not(:placeholder-shown) ~ label {
  top:-18px !important;
  font-size:14px !important;
  color:rgb(255, 255, 255) ;
}
/* active state */
 .floating-input:focus ~ .bar:before, .floating-input:focus ~ .bar:after{
  width:50% !important;
}
*, *:before, *:after {
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    box-sizing: border-box !important;
}
/* highlighter */
 .highlight {
  position:absolute;
  height:50%;
  width:100%;
  top:15%;
  left:0;
  pointer-events:none;
  opacity:0.5;
}
/* active state */
#contact-us .floating-input:focus ~ .highlight{
  /*-webkit-animation:inputHighlighter 0.3s ease;
  -moz-animation:inputHighlighter 0.3s ease;
  animation:inputHighlighter 0.3s ease;*/
}
/* animation */
@-webkit-keyframes inputHighlighter {
from { background:#5264AE; }
  to { width:0; background:transparent; }
}
@-moz-keyframes inputHighlighter {
from { background:#5264AE; }
  to { width:0; background:transparent; }
}
@keyframes inputHighlighter {
from { background:#5264AE; }
  to { width:0; background:transparent; }
}
/****  floating-Lable style end ****/

.border-left {
	border-left: 1px solid gray!important;
	padding-left: 40px;
}
textarea.form-control {
	height:250px;
}
.form-control{
	margin-top:10px;
}
.form-control:not(:last-child){
	margin-top:10px;
}

.nopadding {
	padding: 0;
}
.modal button.close {
	background-image: url(v2/images/x_white.png)!important;
	color: white!important;
}
.close{
	opacity: 0.8;
}
.close span {
	display:block;
	background-size: cover;
	background-repeat: no-repeat;
	width: 28px;
	height: 28px;
}

::-webkit-input-placeholder {
	color: #5b5b5b!important;
}

:-moz-placeholder { /* Firefox 18- */
	color: #5b5b5b!important;
}

::-moz-placeholder {  /* Firefox 19+ */
	color: #5b5b5b!important;
}

:-ms-input-placeholder {
	color: #5b5b5b!important;
}

.reset, .full{
	background-color: #f6f6f6;
}

.reset div[data-role="response"]{
	margin-top: 5%;
}

.full footer {
	position:absolute;
	bottom:0;
	width:100%;
}

/*Select currency and language*/
.form-control.currency,
.form-control.language {
	border-radius: 0; -webkit-appearance: none;text-align:center;-moz-appearance: none;
}

#message form {
	color: #5b5b5b;
}

.modal-backdrop.in {
	opacity: 0.6;
}

.navbar-toggle{
	padding-top: 0px;
}

header .navbar-header {
	/*max-width: 250px;*/
	line-height: normal;
	margin-top: 10px;
	vertical-align: middle;
}

#terms-conditions h2,
#privacy-policies h2 {
	font-size: 24px;
	margin: 0;
	padding-bottom: 20px;
}

#terms-conditions .bordered,
#privacy-policies .bordered{
	padding: 40px;
}


.tab-option{
	width:50%;
	text-transform: uppercase;
}

.tab-option a {
	color: #5b5b5b;
}
.tab-option .active a {
	font-weight: 800;
}

.tab-panel .tab-content {
	margin-top:-5px;
}


.tab-panel {
	background-color:#F6F6F6;
}
.tab-panel .bordered {
	background-color: white;
}
.tab-panel hr {
	margin-bottom: 5rem;
	width: 100px;
	border-top: 3px solid rgb(98,197,202) !important;
}
.tab-panel h3 {
	margin: 0;
	padding-bottom: 5px;
	letter-spacing: 3px;
}

#register-tab .bordered{
	padding: 5rem 10rem;
}


@media (-webkit-min-device-pixel-ratio:0)
{
	.form-control.currency {padding-left:calc(50% - 60px);}
	.form-control.language {padding-left: calc(50% - 35px);}
}

@-moz-document url-prefix() {
	.form-control.currency,
	.form-control.language {padding-left:0;}
}


@media (max-width: 768px){
	/*#background-beach{
		height: 500px;
	}

	#background-beach .description h2 {
		font-size:30px;
	}

	#background-beach .description {
		font-size:22px;
	}*/

	footer div.left, footer div.right {
		text-align: center;
	}

	footer div.right li,
	footer div.right ul{
		padding:0;
		margin:0;
		display:block;
	}


}

.carousel-control {
	background-repeat: no-repeat;
	background-size:contain;
	background-position: center center;
	width:35px;
	height:35px;
	display:block;
	top: calc(50% - 20px);
	position:absolute;
	opacity: 1 !important;
}

.carousel-control  span {
	display: block;
	width: 35px;
	height: 35px;
	background-size: contain;
}
@media (max-width: 767px){
	header a.main-logo {
		position: relative;
		top: 0px;
		left: 10%;
		right: 0;
	}


	header .navbar-header {
		max-width: 100%!important;
		margin:0!important;
		padding:0;
	}

	.navbar-toggle {
		margin:0;
		padding:0;
	}

	.logo-header {
		max-width: 200px;
		margin: 30px 0;
	}



	header a.main-logo span{
		padding: 10px;
	}

	header a.main-logo img {
		width: 70px;
	}

	.border-left {
		border-left: 0;
		padding:0;
	}
}

.videoWrapper {
	top: 45px;
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


/* This parent can be any width and height */
.block {
	text-align: center;
}

/* The ghost, nudged to maintain perfect centering */
.block:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle;
	margin-right: -0.25em; /* Adjusts for spacing */
}

/* The element to be centered, can
also be of any width and height */
.centered {
	display: inline-block;
	vertical-align: middle;
	width: 300px;
}
#quote-carousel2 .carousel-inner {
	position: relative;
	text-align: center;
	right: 0;
	top: 0;
	height: auto;
	bottom: 0px;
	margin-top: 2em;
}
		/* QUOTE CARROUSEL */
		.comment-quote {
			font-weight: bold;
			display: inline-flex;
			align-content: center;
		}
		.comment-quote span {
			padding-left: 0.6em;
			padding-right: 0.6em;
		}
		#quote-carousel2 a.left{
			left: 10rem;
		}
		#quote-carousel2 a.right img,
		#quote-carousel2 a.left img{
			width: 60%;
		}
		#quote-carousel2 a.right{
			right: 10rem;
		}
    #quote-carousel2 {
			margin-right: 13em;
			padding-left: 10em;
			padding-right: 10em;
			font-size: 16px;
			margin-top: 60px;
			margin-left: 13em;
			position: absolute;
			z-index: 1;
		}
			#quote-carousel2 .carousel-control {
				position: absolute;
				top: 2em;
				bottom: 0;
				margin: 0;
				padding: 0;
				width: 2.5em;
				height: 3em;
				z-index: 100;
			}
			#quote-carousel2 .carousel-indicators.q-clientes {
			position: relative;
			right: 50%;
			top: auto;
			bottom: 0px;
			margin-top: 20px;
			margin-right: -19px;
			}
			#quote-carousel2 .carousel-indicators.q-clientes li {
				width: 5em;
				height: 5em;
				cursor: pointer;
				box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
				border-radius: 50%;
				opacity: 0.4;
				overflow: hidden;
				transition: all .4s ease-in;
				vertical-align: middle;
				margin-left: 1.5em;
				margin-right: 1.5em;
			}
			#quote-carousel2 .img-responsive{
				margin-bottom: 0;
				width: auto;
				margin: 0 auto;
				margin-top: 1%;
			}
			#quote-carousel2 .carousel-indicators.q-clientes .active {
			width: 130px;
			height: 130px;
			opacity: 1;
			transition: all .2s;
			}
			#quote-carousel2 .block-quote h4{
				color: #45c6c8;
				font-weight: bold;
			}
			#compass-img {
				position: absolute;
    		width: 100%;
			}
.carousel-control.left, .carousel-control.right, .carousel-control{
  background-image: none!important;
  background-color: transparent!important;
}
#gdsclients{
	position: relative;
	height: 100vh;
}
.carousel .item {
	min-height: 500px;
}
#services,#companies,#who-we-are, #carousel-offers,#carousel-offers-m,#carousel-services-m {
	padding-left: 10em;
	padding-right: 10em;
	background: #ffffff;
}
#services, #companies,#carousel-offers, #carousel-offers-m, #carousel-services-m {
	margin-top: -10px;
}
.weeksCarrousel{
	position: relative;
  padding-right:0%!important;
	padding-left: 0%!important;
	margin: auto;
}
.weeksCarrousel a.carousel-control img{
	right: inherit;
	left: inherit;
}
.weeksCarrousel a.left {
	position: absolute;
	top: 15em;
	left: 1em;
	right: 0;
	margin: 0;
}
.weeksCarrousel a.right {
	position: absolute;
	top: 15em;
	right: 1.5em;
	margin: 0;
}

.carousel .item .carousel-caption {
	text-shadow: none;
	color: inherit;
}

.checkbox label:after,
.radio label:after {
	content: '';
	display: table;
	clear: both;
}

.checkbox .cr,
.radio .cr {
	position: relative;
	display: inline-block;
	border: 1px solid #a9a9a9;
	border-radius: .25em;
	width: 1.3em;
	height: 1.3em;
	float: left;
}

.radio .cr {
	border-radius: 50%;
}

.radio, .checkbox{
	margin-bottom: 0;
}

.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
	position: absolute;
	font-size: .8em;
	line-height: 0;
	top: 50%;
	left: 20%;
}

.radio .cr .cr-icon {
	margin-left: 0.04em;
}

.checkbox label input[type="checkbox"],
.radio label input[type="radio"] {
	display: none;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon,
.radio label input[type="radio"] + .cr > .cr-icon {
	transform: scale(3) rotateZ(-20deg);
	opacity: 0;
	transition: all .3s ease-in;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
.radio label input[type="radio"]:checked + .cr > .cr-icon {
	transform: scale(1) rotateZ(0deg);
	opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled + .cr,
.radio label input[type="radio"]:disabled + .cr {
	opacity: .5;
}

.checkbox i.destination-name{
	display: inline-block;
	width: 170px;
	vertical-align: top;
	white-space: pre-wrap;
	overflow: inherit;
}

.navbar-nav a {
	color:#fff!important;
}

.fb-linkto a{
	color: white;
	background-color: #4a63a9;
	padding: .9rem;
	display: block;
}

.link-right{
	line-height: 3;
	color:inherit;
}

.link-right:after{
	content: "\203A";

}

li.open a {
	background-color: transparent!important;
}


header.top .dropdown-menu  a{
	padding: 8px 15px;
	/*border-bottom: 1px solid white;*/
}

.dropdown-menu {
	width:100%;
	background-color: inherit!important;
	border:inherit!important;
	box-shadow: inherit!important;
	text-transform: uppercase;
}

header.top .dropdown-menu{
	background-color: rgb(48,48,48);
	border: 1px solid white!important;
	margin-top: 10px;
	border-radius: 0!important;
	padding: 0;
}

.details-box {
	background-color: white;
	border: .1rem solid #dededf;
	margin: 5px;
	padding: 3rem 2rem;
	color: inherit;
	margin: 0.5rem .5rem 0 0;
	text-align: left;
}

.breadcrumb {
	background-color:#e0e0e0;
}

.breadcrumb a{
	color: #000;
	text-transform: uppercase;
}

.details-box h4{

}
.details-box a[data-role="change"] {
	border-radius: 0.2rem!important;
	cursor: pointer!important;
}


.details-box label {
	padding:0;
}


.details-box .input-group {
	display:block;
}

.details-box .form-control {
	width:100%;
	margin:0;
	margin-bottom: 10px;
	text-align: left;
}

.details-box a[data-role="change"], .details-box a[data-role="change"]:link, .details-box a[data-role="change"]:hover, .details-box a[data-role="change"]:active, .details-box a[data-role="change"]:visited, .details-box div[data-role="submit"], .details-box div[data-role="submit"]:link, .details-box div[data-role="submit"]:hover, .details-box div[data-role="submit"]:active, .details-box div[data-role="submit"]:visited, #avatar a[data-role="change"], #avatar div[data-role="submit"] {
	width: 100%;
	background-color: #5b5b5b;
	display: block;
	color: white;
	text-align: center;
	padding: 1rem 2rem;
	cursor: pointer;
	font-size: 1.6rem;
	border-radius: .2rem;
	text-transform: capitalize!important;
}

.subtitle h3{
	color:inherit;
	padding: 3rem;
	font-size: 2.8rem;
}



.change-avatar,
.avatar-thumb {
	height: 200px;
	width: 200px;
	background-position: center center;
	background-size: cover;
	border-radius: 100px;
	display: block;
	position: static;
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	display: block;
	overflow: hidden;
	position: relative;
	cursor: pointer;
	margin-bottom: 2rem;

}

.avatar-thumb {
	margin: 0!important;
	top: 0;
	vertical-align: text-top;
	padding: 0;
}

.change-avatar img,
.avatar-thumb img{
	display: inline;
	width: 100%;
	object-fit: cover;
	height: 200px;
	left: 0;
	right: 0;
	position: absolute;
}

.avatar-thumb img{
	height: inherit;
	margin: 0;
	bottom: 0;
}

.pick {
	display: block;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.4);
	color: white;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height:50px;
	padding: 0 0 6px;
	font-weight: 300;
}

#avatar input[type=file]{
	display:none;
}

.fit-in {
	background-position: center center;
	background-size: cover;
}

.tab-panel.modal-body {
	color:inherit;
}

.plans {
	flex-direction: column;
	float: left;
	width: 33.3%;
	padding: 8px;
}

.plans .description {
	position: relative;
	text-transform:uppercase;
	font-weight:600;
	font-size:20px;
	padding-top:30px!important;
}
.plans .information {
	list-style-type: none;
	margin: 0;
	padding: 0;
	-webkit-transition: 0.3s;
	transition: 0.3s;
	min-height: 375px;
	height:auto;
	position:relative;
}

.plans .information:hover,
#most-popular .information{
	box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2)
}

.plans .information .header {
	position: relative;
	color: white!important;
	font-size: 1.9rem;
	font-weight: 600;
	padding: 15px;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.plans .information li {
	padding: 15px 20px;
	text-align: center;
	font-size: 1.8rem;
	font-weight: 600;
	color: white;
}
.plans .information hr{
	margin-bottom: 0;
	width: 90px;
	border-top: 4px solid rgb(77,77,77)!important;
  background-color: transparent;
}
.plans .information li:last-of-type {
	margin-top: auto;
	position: absolute;
	bottom: 0;
	width: 100%;
}

.plans .checkbox label,
.plans .checkbox{
	margin:0;
	padding:0;
}

.plans .checkbox span.cr {
	color:#0c0c0c!important;
}
.plans .price{
	position: relative;
	font-size: 5rem!important;
	padding: 0!important;
}

.plans .price span:first-of-type{
	font-size: 2.2rem;
	vertical-align: middle;
}

.btn-payment {
	border: none;
	color: white;
	padding: 10px 25px;
	text-align: center;
	text-decoration: none;
	font-size: 18px;
	border-radius:2px;
	margin: 0 !important;
}

.plans .header {
	position: relative;
}

.plans .btn-payment {
	margin-top: 0!important;
	margin-bottom: 20px!important;
	background-color: rgba(0,0,0,.1) !important;
}

#standard .btn-payment {
	background-color: rgb(77,77,77) !important;
}
#standard form {
	background-color: rgb(112, 122, 112)!important;
}
#most-popular form {
	background-color: rgb(73, 199, 201)!important;
}
#best-deals form {
	background-color: rgb(247, 198, 81)!important;
}
#standard li:nth-child(4){
	padding:10px 10px 10px 25px!important;
}

#standard .information,
#best-deals .information{
	box-shadow: 0 1px 12px 0 rgba(0,0,0,0.2);
}

.modal-body .reactivation{
	color: gray;
}

.modal-body .reactivation a:not(.btn-continue) {
	color:inherit!important;
	font-size:inherit!important;
	font-weight: 600;
}
.reactivation .bordered {
	padding-top:60px;
	padding-bottom:60px;
}
.reactivation .uppercase {
	text-transform: uppercase;
}

.reactivation hr {
	margin-top: 15px;
	margin-bottom: 20px;
}

.reactivation img {
	display: block;
	margin: 0 auto;
	max-width: 75px;
	margin-bottom: 15px;
}

#your-plan .bordered{
	padding: 0;
	margin: 1rem;
}

#renew .bordered {
	margin:0;
}

#renew .tab-option {
	width: 100%;
	margin: 0;
	padding: 0;
}

#renew .tab-option a{
	margin: 0!important;
	border-radius: 0!important;
	text-align: center;
	font-weight: 600;
}

#renew hr {
	margin-bottom: 15px;
	margin-top: 15px;
}

#renew  p {
	color:gray;
}

#renew  .tab-content {
	font-size:1.6rem;
	color:#5b5b5b!important;
}

#expiration-modal .modal-body{
	padding: 5px;
	padding-bottom: 30px;
}

#expiration-modal .modal-body p{
	padding:30px 15px;
}

#expiration-modal .abuttons a{
	display: block;
	margin: 0;
}

#expiration-modal .modal-body {
	display:table;
}

#expiration-modal .tab-option {
	text-transform: uppercase;
	width: 100%;
	font-weight: 600;
	border-bottom: 1px solid #DADADA;
}

#expiration-modal .tab-option  a{
	border:none;
}

#expiration-modal .modal-body .plans p{
	padding:0;
	margin-top:30px;
}

#terms-conditions [class="text-justify"],
#privacy-policies [class="text-justify"]{
	overflow: scroll;
	height: 300px;
	padding-left:0;
}

#terms-conditions ol {
	list-style: none;
	margin:0;
}
#reset-modal .bordered,
*[data-role="submit"]{
	text-align: center!important;
}
#reset-modal h3{
	/*color: white!important;*/
}

#linktofb button {
	display:none;
}

.alert-danger {
	margin-top: 20px;
}
/***** VERSION MOBIL GDS****/
@media only screen and (max-width: 992px){
	#header-mobil{
		padding: 0 3rem;
		min-height: 100px;
		display: flex !important;
    align-items: center;
		align-content: center;
		background-color: #111111;
	}
	#header-mobil button{
		position: absolute;
		right: 3rem;
		background: transparent;
    border: 0;
	}
	 .head-belt {
		margin-top: 10rem;
	}
	.tab-content {
	    display: block !important;
	}
	.titleblck h3, .titleblck-left h3, h3,.details-box h3,
	.corcierblck h3 {
		font-size: 30px !important;
	}
	.titleblck h2,
	.white-belt h2 {
		font-size: 40px;
	}
	footer div {
		font-size: 20px;
	}
	footer ul {
		padding: 0;
    text-align: left;
	}

	footer ul li {
		/* display: inline-block; */
    margin: 0;
    width: 100%;
    font-size: 30px;
    text-align: center;
	}
	#account-info a, .form-data,
	.whatrewis p ,.titleblck p, .titleblck-left p, b, p,.details-box a[data-role="change"],#avatar a[data-role="change"],
	.corcierblck p b, footer ul li {
		font-size: 25px !important;
	}
	.offersimg{
		z-index: -1;
	}
	header {
		position: fixed !important;
		text-align: left;
    background-color: #000000!important;
    width: 100%!important;
    z-index: 1050!important;
		padding: 0px;
		min-height: 100px;
	 }
	 #quote-carousel2 {
		position: relative;
    margin: 0;
		padding: 0;
		margin: 10px;
	 }
	 .corcierblck ol li ,
	 .corcierblck ul li {
		font-size: 20px !important;
	 }
	 .corcierblck p,
	 #quote-carousel2 p{
		font-size: 25px !important;
		text-align: justify !important;
	 }
	 #quote-carousel2 a.right {
    right: 0;
	}
	#quote-carousel2 a.left {
		left: 0;
	}
	 #services, #companies, #who-we-are, #carousel-offers,#carousel-offers-m,#carousel-services-m{
		padding: 1rem;
		margin-top: -2px;
	 }
	 #contact-us {
		padding: 5rem;
		height: auto;
	 }
	 .floating-label, .floating-label {
		margin-bottom: 7rem;
	 }
	 .floating-label label,
	 .floating-input {
		font-size: 24px;
		height: 50px;
	 }
	 .floating-input:focus ~ label,
	 .floating-input:not(:placeholder-shown) ~ label{
		font-size: 20px !important;
	 }
	 .btn-contact-send{
		 font-size: 30px;
	 }
	 #blackmenu-modal{
		background: rgb(245, 245, 245);
	}
	.blackmenudata ul li{
		font-size: 24px;
		color: rgb(78, 78, 78)!important;
		text-transform: uppercase;
	}
	#profile-option,#account-info{
		margin-top: 10rem !important;
	}
	.blackmenudata ul li a{
		font-weight: 100;
    color: rgb(78, 78, 78)!important;
    text-decoration: none!important;
    font-size: 30px;
    letter-spacing: 7px;
	}
	.blackmenudata hr {
		width: 100% !important;
		border-top: 4px solid rgb(98,197,202) !important;
		margin-top: 4rem;
	}
	.blackmenudata ul li{
		margin-top: 60px!important;
		list-style: none !important;
	}
	#register-modal,
	#login-modal{
		z-index: 9999999;
		background-color: white;
	}
	 .modal button.close{
    background-image: url(../images/icons/CruzIcono.jpg)!important;
    color: white!important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
	}
	.close span {
		width: 50px;
    background-position: center;
    height: 50px;
	}
	.membershio-blck {
		margin: 0 5rem;
	}
	.luxuryresorts, .cruiseholidays,
	.carrental, .homedeluxe, .holidayactivities{
		padding: 0;
	}
	footer a, footer a:hover, footer a:visited, footer a:link, footer a:active {
    color: inherit;
    font-size: 20px;
	}
	.titleblck {
		margin:3rem 0;
		text-align: justify;
	}
	.tableimg1 img, .tableimg2 img,.see-reward img{
		width: 100% !important;
		max-width: 100% !important;
	}
	.see-reward {
		display: block !important;
		text-align: center;
	}
	.program{
		margin: 0 !important;
	}
	#avatar{
		margin: 5rem;
	}
	.change-avatar {
		margin-left: 20rem;
	}
	#account-info .details-box {
		margin-bottom: 2rem;
	}
	.infoOffer{
		margin-top: 15rem;
	}
	.offersimg {
		position: initial !important;
		max-height: inherit !important;
	}
	.carousel-indicators.infoindicators {
		top: 0rem !important;
	}
	#quote-carousel2 .block-quote h4 {
		font-size: 25px !important;
	}
	.whatrewis{
		text-align: justify;
		padding: 1.5rem !important;
	}


}/*END VERSION MOBIL*/
@media only screen and (min-width: 1200px){
	#myCarousel .carousel-indicators .downmouse {
			margin: 11em auto;
	}
}
@media only screen and (min-width: 1600px){
	#myCarousel .carousel-indicators .downmouse {
			margin: 15em auto;
	}
}
@media(max-width: 1025px){
/*	.navbar-header img:not(#hamburguer){
	    max-height: 21px !important;
		margin: 2.3rem 3rem !important;
	}*/
	#myCarousel .carousel-indicators .downmouse{
		margin: 10em auto ;
	}
	.program{
		margin: 0 !important;
	}
	.navbar-header{
		max-width: 100% !important;
	}
	.collapse.in{
		padding-top: 3rem;
		position: relative;
		z-index: 100;
	}
	.white-belt{
		margin-top: -1rem;
	}
	#who-we-are { margin-top: 0;}
	#services, #companies, #carousel-offers, #carousel-offers-m,#carousel-services-m{
		padding:2rem 1rem;
		margin-top: -1rem;
	}
	.weeksCarrousel a.right {
		right: 1em;
	}
	.weeksCarrousel a.left {
		left:0rem;
	}
	#quote-carousel2 {
		margin: 0 15rem;
		padding: 0;
	}
	#quote-carousel2 a.right {
    right: 0;

	}#quote-carousel2 a.left {
    left: 0;
	}
	#contact-us {
		padding: 8rem 10rem;
		height: 100%;
	}


}
@media only screen and (max-width: 600px) {
	#header-mobil a img {
    width: 75% !important;
	}
	.plans{
		width: 100%;
	}
	#register-modal .modal-dialog,
	#message .modal-dialog {
		width: unset;
	}
}
@media(max-width: 769px){
	#login-modal{
			background: #ffffff !important;
	}
	#login-modal .bordered{
		padding: 5rem 3rem !important;
	}
	.img-src {
		display: flex;
    align-items: center;
    align-content: center;
    flex-direction: column;
		justify-content: center;
		position: absolute;
		height: 100%;
	}
	.img-src .overlay h3 {
		color: white;
		margin: 0;
		padding: 0;
	}
	.img-src img {
		width: 55%;
	}
	.img-src .overlay  {
		opacity: 1;
		background-color: transparent;
	}
	#account-info, #profile-option{
		margin-bottom: 0;
	}
	#contact-us {
		padding: 15rem 1rem
	}
	.block-quote{
		padding: 0;
	}
	#gdsclients {
		/* padding-top: 15rem; */
    height: 100%;
    /* padding-bottom: 10rem; */
		height: 100rem;
	}
	#quote-carousel2 {
    margin: 0 !important;
    padding: 0 !important;
	}
	.membershio-blck {
    margin: 0 3rem;
}
	#header-mobil a img {
    width: 85%;
	}
	#loading {
		top: 10%;
		width: 100%;
		padding: 0;
		height: initial;
	}
	#loading .brand-logo img{
		width: 80%;
		max-width: 75px;
	}
	.navbar-toggle{
		padding: 0 !important;
	}
	#register-modal .title{
		padding-top: 2rem !important;
    background-position: calc(50%) calc(10%) !important;
	}
}
@media(min-width: 768px){
	#loading {
		left: calc(50% - 281px);
	}
}
@media(max-width: 425px){
	#register-tab .bordered {
    padding: 5rem 2rem;
	}
	.white-belt h2 {
		letter-spacing: 1rem !important;
    font-size: 4rem !important;
	}
	#message .modal-dialog {
		margin: 0;
		background: white;
	}
	#login-modal form {
		padding-top: 0 !important;
	}
	.blackmenudata ul {
		padding: 0 !important;
	}
	#login-modal .title {
		background-position: calc(52%) calc(15%);
    padding-top: 2rem;
	}
	.carousel-indicators {
		top: 13em;
	}
	#myCarousel .carousel-indicators .downmouse {
    margin: 5em auto;
	}
	.white-belt h4 {
		font-size: 18px !important;
	}
	.change-avatar {
		margin-left: 0;
	}
	#who-we-are {
		height: 100% !important;
	}
	.modal button.close {
		margin: 0 4rem 0 !important;
	}
	#message .modal-body ,#login-modal,
	.carousel-inner div {
		padding: 0 !important;
	}
	.weeksCarrousel a.left,.weeksCarrousel a.right {
		top: 18em;
	}
	.carousel-inner img,
	.img-src img {
		width: 100% !important;
	}
}

@media (min-width: 320px) and (max-width:767px) {
	#loading {
		left: 0!important;
		right: 0!important;
	}
	.navbar-header {
		float: none;
	}
	.navbar-toggle {
		display: block;
	}
	.navbar-collapse {
		border-top: 1px solid transparent;
		box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
	}

	.navbar-collapse.collapse {
		display: none!important;
	}
	.navbar-collapse.collapse.in{
		display: block!important;
	}
	.navbar-nav {
		float: none!important;
		margin: 7.5px -15px;
	}
	.navbar-nav>li {
		float: none;
	}
	.navbar-nav>li>a {
		padding-top: 10px;
		padding-bottom: 10px;
	}
}
#who-we-are {
	padding-top: 15rem;
}
#who-we-are p{
	font-size: 2.5rem;
    font-weight: 100;
    line-height: 1.6;
	letter-spacing: 0.03em;
	color: rgb(115, 115, 115);
}
.servicestitle{
	width: 100%;
	padding-bottom: 10px;
  }
  .imagesServices{
		margin-left: 0px;
		margin-right: 0px;
		padding-left: 0px;
		padding-right: 0px;
		width: 100%;
	}
	.imagesServices img {
		width: 100%;
    max-width: 100%;
		height: 100%;
		font-size: 16pt;
	}
	.imagesServices h1 {
		color: white;
    position: absolute;
    font-size: 17px;
    letter-spacing: 3px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 50%;
    height: 15%;
    margin: auto;
	}
	/* eliminar este bloque de css*/
  .img1,.img2,.img3,.img4,.img5,.img6,.img7,.img8,.img9{
	background-size: cover;
	background-repeat: no-repeat;
	height: 250px;
	color: white;
	font-size: 16pt;
	margin-left: -3px;
	margin-right: 0px;
	padding-left: 0px;
	padding-right: 0px;

  }

  .img5{
	background-image: url('https://repository.mundoinspira.com/newblack/black_third5.png');
  }
  .img6{
	background-image: url('https://repository.mundoinspira.com/newblack/black_third6.png');
  }
  .img7{
	background-image: url('https://repository.mundoinspira.com/newblack/black_third7.png');
  }

.overlay {
	position: absolute;
	margin: 0 0.3em 0.67em 0.38em;
	padding: 1rem;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	transition: .5s ease;
	background-color: rgba(0,0,0,.7);
	}
	.img-src{
		padding: 0 0.5rem 1rem 0.5rem;
	}
	.imagesServices .img-src:hover .overlay,
	.img5:hover .overlay,.img6:hover .overlay,.img7:hover .overlay {
		opacity: 1;
  }
  .servicestitle, .offcomp{
		color: white;
  }

.text {
		position: relative;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: white;
	font-size: 16px;
	font-weight: 500;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	text-align: center;
  }
  .marginbl{
	padding: 0 0 0rem!important;
	color: #ffffff;
	}
#services {
	padding-bottom: 10rem;
}
/**** Animation scale Carrousel ****/
/*@media (min-width: 768px) {*/
#myCarousel	.carousel-inner img {
			   animation: zoomImage infinite;
			  animation-delay: 0.3s;
			  animation-timing-function: linear;
			  animation-fill-mode: both;
			  animation-duration: 80s;
			  backface-visibility: hidden; 
	}
#services	.carousel-inner img:hover  {
				opacity: 0;
	}
	#services .carousel-inner img:hover img {
				transform: none;
	}
/*}*/
#myCarousel .carousel-inner {
	height: 100vh;
	object-fit: cover;
}
.carousel-inner img {
	transition: transform 0.6s ease-in-out;
}
@keyframes zoomImage {
	0% {
		opacity: 1;
		transform: scale3d(1, 1, 1);
	}
	50% {
		opacity: 1;
		transform: scale3d(1.4, 1.4, 1.4);
	}
	100% {
		opacity: 1;
		transform: scale3d(1, 1, 1);
	}
}
.img-responsive {
    margin-bottom: 1rem;
		width: 20%;
    text-align: center;
    margin-left: 35%;
    margin-right: 35%;
    margin-top: 5%;
  }

.item{
    margin-bottom: 65px;
}

.carousel-fade .carousel-inner .item {
  -webkit-transition-property: opacity;
	transition-property: opacity;
	margin: 0 auto;
}
.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  opacity: 0;
}
.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.carousel-fade .carousel-control {
  z-index: 2;
}
.carousel-indicators.infoindicators {
	position: relative;
	top: -2em;
	bottom: 0;
	left: 0;
	right: 0;
	width: auto;
	height: auto;
	margin: auto;
	padding: 0;
}
.carousel-indicators.infoindicators .active{
	background-color: #45c6c8;
}
.carousel-indicators.infoindicators li{
	border: 2px solid #45c6c8;
}
.offersimg{
	min-height: 26em;
	max-height: 26em;
	width: 100%;
	position: relative;
}
.infoOffer{
	margin-top: 1em;
	height: 25rem;
	min-height: 25rem;
}
.infoOffer h3{
	font-weight: 900!important;
	color:  #45c6c8;
	font-size: 25px;
	margin: 15px !important;
  padding: 0;
}
.infoOffer p {
	color: #333333;
	font-weight: bold;
}
.infoOffer label {
	color: #6a6a6a;
}
.expedia_logo, .booking_logo {
	width: 100px;
}
.offersimg h2 {
		position: absolute;
	  font-weight: 500!important;
		letter-spacing: 4px;
		color: white;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 15%;
    margin: auto;
}

/**** Informative ****/
.see-reward{
	display: inline-block;
	align-items: center;
	align-content: center;
	margin-bottom: 4rem;

}
.boomerang-rewards{
	display: inline-flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	background-color:rgb(73, 200, 202) ;
	color:#ffffff;
	padding-right: 0;
}
.boomerang-rewards img {
	width: 27%;
}
.boomerang-rewards h3{
	color: #ffffff !important;
}
ol.olcolor { counter-reset: item; }
ol.olcolor li { display: block; }
ol.olcolor li:before {
		content: counter(item) ". ";
		counter-increment: item;
		color: #2dc3c5;
}
.luxuryresorts,.cruiseholidays,.carrental,
.homedeluxe,.holidayactivities{
/*	padding-left: 10em;
	display: inline-flex;
	padding-right: 10em;
	align-items: center;
	background: #ffffff;
	align-content: center;
	width: 100%;*/
}
.corcierblck {
	text-align: center;
}
.concierblck h3{font-size: 25px;}
#wrapper .title h2 {
	font-size: 36px;
	margin: 1%;
	letter-spacing: 10px;
	font-weight: 900;
}
.membershio-blck{
	padding: 0;
}
.divline {
	position: relative;
	bottom: 0;
	margin: 3% 20% 0;
	width: 50%;
}
.membership-details .head-belt{
  background-image: url('https://repository.mundoinspira.com/gdsvacations/informative/membership-details/GDSMembership.png')!important;
  background-repeat: no-repeat!important;
	background-position: center;
	background-size: cover;
	-webkit-background-size: cover!important;

}
.concierge .head-belt{
  background-image: url('https://repository.mundoinspira.com/gdsvacations/informative/concierge/GDSConcierge.png')!important;
  background-repeat: no-repeat!important;
	background-position: center;
	background-size: cover;
	-webkit-background-size: cover!important;

}
.partners .head-belt{
  background-image: url('https://repository.mundoinspira.com/gdsvacations/informative/partners/Partners.png')!important;
  background-repeat: no-repeat!important;
	background-position: center;
	background-size: cover;
	-webkit-background-size: cover!important;
}
.price-guarantee .head-belt{
  background-image: url('https://repository.mundoinspira.com/gdsvacations/informative/price-guarantee/GDSPriceGuarantee.png')!important;
  background-repeat: no-repeat!important;
	background-position: center;
	background-size: cover;
	-webkit-background-size: cover!important;
}
.rewards-program .head-belt{
  background-image: url('https://repository.mundoinspira.com/gdsvacations/informative/rewards-program/GDSRewardsProgram.png')!important;
  background-repeat: no-repeat!important;
	background-position: center;
	background-size: cover;
	-webkit-background-size: cover!important;
}
.head-belt{
	position: relative;
		height: 50vh;
		display: flex;
    justify-content: center;
    align-items: center;
}
.head-belt .extratitle, .head-belt .extratitle h2 {
	text-align: center;
	color: #ffffff;
	margin-top: 0;
	margin-bottom: 0;
}
.titleblck h3,.titleblck-left h3{
	font-weight: bold;
	padding: 0;
}
.titleblck-left{margin-left: 0;}
.titleblck hr {
	position: relative;
	border-top: 4px solid rgb(98,197,202) !important;
	width: 10rem;
	margin: 0;
	padding: 5px;
	background-color: transparent;
}
